//using the infura.io node, otherwise ipfs requires you to run a daemon on your own computer/server. See IPFS.io docs

const ipfsClient = require('ipfs-http-client');
 
//const https = require('https');

//const IPFS = require('ipfs-api');

 


const projectId ='2ItqlZHjf1XSeh4cvNubm6nc4mP';
const projectSecret='d66ffeaebebf036ac3b0f3f34f04f243';
//https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Authorization
//auth = 'Basic' + base64(projectId+ ':' +projectSecret);
const auth = 'Basic ' + Buffer.from(projectId + ':' +projectSecret).toString('base64');
//Basic Mkl0cWxaSGpmMVhTZWg0Y3ZOdWJtNm5jNG1QOmQ2NmZmZWFlYmViZjAzNmFjM2IwZjNmMzRmMDRmMjQz
//btoa is not supported in node. 
//const authorization = 'Basic ' + btoa(projectId + ':' +projectSecret);
//const auth = 'Basic ' + Buffer.from(projectId + ':' +projectSecret).toString('base64');
//const auth = 'Bearer ' + Buffer.from(projectId + ':' +projectSecret).toString('base64');
//const auth = 'Bearer ' + (projectId + ':' +projectSecret);
//Bearer 2ItqlZHjf1XSeh4cvNubm6nc4mP:d66ffeaebebf036ac3b0f3f34f04f243
//console.log(auth)
//console.log(IPFS)
//const ipfs = new IPFS({host: 'ipfs.infura.io', port: 5001, protocol: 'https'}); 
//const ipfs = new IPFS({host: 'ipfs.infura.io', port: 5001, protocol: 'https', auth: projectId + ':' + projectSecret});

//const ipfs = new IPFS({host: 'ipfs.infura.io', port: 5001, protocol: 'https', method: 'POST', headers: {Authorization: auth }});
//https://iftpatent.infura-ipfs.io:5001/api/v0/

/*const ipfs = new IPFS ({
     //host: 'ipfs.infura.io',
     //host: 'nftpatent.infura-ipfs.io',
     //host:'gateway.ipfs.io', 
     host: 'ipfs.infura.io',
     port: 5001,
     protocol: 'https',
     method: 'POST',
     path: '/api/v0/pin/add?arg=QmeGAVddnBSnKc1DLE7DLV9uuTqo5F7QbaveTjr45JUdQn',
     headers: {
     "Authorization": 'Basic Mkl0cWxaSGpmMVhTZWg0Y3ZOdWJtNm5jNG1QOmQ2NmZmZWFlYmViZjAzNmFjM2IwZjNmMzRmMDRmMjQz', 
     },
});*/


//const ipfs = await ipfsClient.create({
  const ipfs = new ipfsClient({  
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    apiPath: '/api/v0',
    headers: {
      authorization: auth
    }
  })

/*headers: {
     'Authorization': 'Basic Mkl0cWxaSGpmMVhTZWg0Y3ZOdWJtNm5jNG1QOmQ2NmZmZWFlYmViZjAzNmFjM2IwZjNmMzRmMDRmMjQz', 
     'Accept': 'application/json',
     'Content-Type':'application/json'
     },
*/


     //path: '/api/v0/pin/add?arg=QmeGAVddnBSnKc1DLE7DLV9uuTqo5F7QbaveTjr45JUdQn',
     //method: 'POST',


 /*    const ipfs = ({
     host: 'ipfs.infura.io',
     port: 5001,
     protocol: 'https',
     path: '/api/v0/pin/add?arg=QmeGAVddnBSnKc1DLE7DLV9uuTqo5F7QbaveTjr45JUdQn',
     method: 'POST',
     auth: projectId + ':' + projectSecret,
     });

let req = https.request(ipfs, (res) => {
    let body = '';
    res.on('data', function (chunk) {
        body += chunk;
    });
    res.on('end', function () {
        console.log(body);
    });
});
req.end();

//const ipfs = new IPFS({host: 'ipfs.infura.io', port: 5001, protocol: 'https'});

//run with local daemon



 //const ipfsApi = require('ipfs-api');
  
 //const ipfs = new ipfsApi('localhost', '5001', {protocol: 'http'});

console.log(ipfs);

console.log(ipfsClient);

console.log(auth);

 

const cid = ipfs.add()
console.log(cid);*/

/*console.log(ipfs);

console.log(ipfsClient);

console.log(auth);
*/
export default ipfs;




import React, {Component} from 'react';
import {Col, Grid, Row} from "react-bootstrap";
import {AppContext} from "../App";

class Token extends Component {

    render() {

        return (
            <AppContext.Consumer>
                {({web3ctx, myToken, actions}) => (
                    <Grid className="App-input">
                        <Row>
                            <Col xs={8} className="header">App Settings</Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <a target={'_blank'} href={'https://ipfs.github.io/public-gateway-checker/'}>
                                    Update IPFS Gateway</a>
                            </Col>
                            <Col xs={5}>
                                <input
                                    type="text"
                                    name="web3ctx.ipfsGateway"
                                    style={{width: "320px"}}
                                    defaultValue={web3ctx.ipfsGateway}
                                    onChange={actions.handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>Contract Token Address. This SmartContract allows you to mint NFT for free but the function of "burn" remains with myself as creator of this Contract. The ability to burn is crucial as it marks the termination of Generic License with your Licensee. As noted before by minting NFT you agree to give me first right of refusal to claim against IP disputes or breaches on your behalf.  </Col>
                            <Col xs={5}>
                                <input
                                    type="text"
                                    name="web3ctx.tokenAddress"
                                    style={{width: "320px"}}
                                    defaultValue={web3ctx.tokenAddress}
                                    onChange={actions.handleInputChange}
                                />
                            </Col>
                        </Row>
                         
                    </Grid>
                )}
            </AppContext.Consumer>
        );
    }
}

export default Token;

import React, {Component} from 'react';
import {Col, Grid, Row} from "react-bootstrap";

class Home extends Component {

    render() {

        return (
            <Grid>
                <Row>
                    <Col xs={8} className="header">Welcome to License NFT Platform for Polygon</Col>
                </Row>
                <Row>
                    <Col xs={8}>
                        <p>
                            LICNFT tokens (ERC 721) allow creator to tokenize a generic licence with assurance of
                            provenance to Licensees to profit up to USD 5.0 mio per year on their works (per NFT).  
                            LICNFT tokens are License NFT covering your work, transferable tradeable burnable on any digital marketplace.

                        </p>

                        <p>In order to mint an LICNFT token you must AGREE below:</p>
                        <ol>
                            <li>In production, verification of authorship is mandatory.</li>
                            <li>However as this is in beta, I have removed this requirement. This is aslo a FREE version </li>
                            <li>Free version comes with its own <a href="https://gateway.pinata.cloud/ipfs/QmQ79ntUXT1HokHd47nCVp3WFidnFnWqr9WXWvMVuYDVtH">Generic License, a MUST read. </a> See "description" part of the metadata input field.</li>
                            <li>By using my <a href="https://polygonscan.com/address/0xcEa0Eae288Ef0e03fae9aad1e71664500A8cCDf8#code">SmartContract </a> you are free to mint/transfer as owner of the NFT only. <code> By minting you agree to give me the first right of refusal to claim any IP disputes or breaches on your behalf. </code> IF you want your own smartcontract where you have 100% control please contact me.  </li>
                            <li>You need to have MATIC tokens (Polygon Network) to list LIVE OpenSea.</li>
                            <li>Provide your media (image) for upload but anything else like pdf doc mp4 you need to upload to your own host server and provide the URL which you need to paste in metadata under external_url. </li>
                            <li>Choose the recipient wallet for your minted token (ie yourself or someone else)</li>
                            <li>Complete the inputs in metadata about your creation as they are linked to the generic contract and will be posted to LIVE OpenSea later</li>
                        </ol>

                        <p>
                            Click on <a href="https://youtu.be/ri5PQMWIXOI">youtube instructions</a> 
                            to get started! <code> Contact us at <a href="https://discord.gg/XzZxFTTN"> Discord </a> or <b> admin at licnft dot com </b> for assistance if necessary. </code>
                        </p>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default Home;
